import { useEffect } from 'react'

import { Link } from 'react-router-dom'



const plays =[
  {id:1,img:'https://dummyimage.com/600x400/000/ffd66d&text=Economy',link:"/bte/gameplay/economy",title:'Economy'},
  {id:2,img:'https://dummyimage.com/600x400/000/ffd66d&text=Stats',link:"/bte/gameplay/stats",title:'Stats',},
  {id:3,img:'https://dummyimage.com/600x400/000/ffd66d&text=Equipments',link:"/bte/gameplay/equipments",title:'Equipments'},
  {id:4,img:'https://dummyimage.com/600x400/000/ffd66d&text=Classes',link:"/bte/gameplay/classes",title:'Classes',},
  {id:5,img:'https://dummyimage.com/600x400/000/ffd66d&text=Monsters',link:"/bte/gameplay/monsters",title:'Monsters',},
  {id:6,img:'https://dummyimage.com/600x400/000/ffd66d&text=Nobility',link:"/bte/gameplay/nobility",title:'Nobility',},

]



const GameplayItem = ({link,title,img}:{link:string,title:string,img:string})=>{
  return(
    <div className='w-full md:w-1/2 md:odd:pr-12 md:even:pl-12 mb-8 group'>
      <Link to={link} >
        <div className='text-center text-xl font-medium mb-4 group-hover:underline  '>{title}</div>
        <div className=' flex flex-col justify-between bg-sub-a h-96 p-8 group-hover:cursor-pointer border-b-2 border-transparent group-hover:border-sub-b rounded-lg transition-colors duration-500'>
          <img src={img} className=" md:object-cover h-full w-full " alt="news" />
        </div>      
      </Link>
    </div>
  )
}


const Gameplay = () =>{
  useEffect(()=>{
    document.title = "Clashing Blades Online | Gameplay"
  },[])
  return(
    <section className='homerest text-white'>
      <div className='flex flex-wrap w-full flex-col-reverse md:flex-row px-4 md:px-24 py-4 md:py-12 '>
        <div className='w-full md:w-1/2 text-3xl md:text-5xl mt-4 md:mt-0' >
          <span className='font-medium'>
            Gameplay
          </span>
        </div>
        <div className='w-full md:w-1/2 flex md:items-center' >
          <nav className="bg-grey-light rounded-md w-full">
            <ol className="list-reset flex justify-start md:justify-end">
              <li><Link to="/bte" className="text-gray-400 hover:text-sub-b">Home</Link></li>
              <li><span className="text-sub-b  mx-2">{'>'}</span></li>
              <li><Link to="/bte/gameplay" className="text-white hover:text-sub-b">Gameplay</Link></li>
            </ol>
          </nav>        
        </div>
      </div>
      <div className='flex justify-center w-full'>
        <div className='w-full flex flex-wrap content-start px-4 py-4 md:py-12  max-w-7xl '>
          {
            plays.map((play)=>(
              <GameplayItem key={play.id} {...play} />
            ))
          }
        </div>
      </div>
    </section>
  )
}


export default Gameplay 