import React , { useEffect,RefObject, useRef,useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper";

import { Link } from  'react-router-dom'

import Button from '../../components/button'


import axios from 'axios';


const sliders =[
  {id:1,image:'slider04.jpg',title:'Clashing Blades Online',btn:'Learn More', link:'/' , linktype:0, text:'The first ever OpenWorld Play2Earn 3D MMORPG on the Blockchain, Dive into the world of Ethernia and learn its culture.'},
  {id:2,image:'slider02.jpg',title:'Own Every Equipment',btn:'Learn More', link:'/' , linktype:0, text:'Your items as NFTs'},
  {id:3,image:'slider03.jpg',title:'Arena Battles',btn:'Learn More', link:'/' , linktype:0, text:'Join other players in exciting competition and great rewards.'},
  {id:4,image:'slider01.jpg',title:'Clashing Blades Community',btn:'Learn More', link:'https://t.me/clashingbladesmmo' , linktype:0, text:'Join us on Telegram'},
]

const LykaData = [
  {id:1,wide:true,img:"/assets/logo.png",title:'Ethernia Universe',btn:'Join',desc:"Lyka is the primary currency used in the world of Ethernia but is also a token available on various trading platforms. Players can exchange it safely while helping grow Ethernia's economy."},
  {id:2,img:"/assets/obtaining.png",title:'Obtaining',btn:'Lets Kill!',desc:'Lyka will drop from monsters in the game with specific limits to avoid abuse. You can get it by completing quests.'},
  {id:3,colored:true,img:"/assets/upgrade.svg",title:'Upgrading',btn:'Fighting!',desc:"As you fight every day in the world of Ethernia, it's insufficient to stay at the same level; you will have to upgrade your skills to become stronger!"},
  {id:4,colored:true,img:"/assets/enchanting.png",title:'Enchanting',btn:'+++',desc:"Pieces of equipment are your lifelong comrades. They are the companions that never leave your side. You will want to enchant them so they can grow powerful with you."},
  {id:5,colored:true,img:"/assets/marketplace.png",title:'Marketplace',btn:'Trade Now!',desc:"Trading with other players is the best way to obtain the items you want, so we have a marketplace for you to buy and sell as you like."},

]

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

const NewsPost = ({index,link,date,title,para,handleHovering,active}:{index:number,active:number,link:string,date:string,title:string,para:string,handleHovering:Function})=>{
  const postref = useRef<HTMLAnchorElement|null>(null)
  useEffect(()=>{
    if(index===active){
      handleHovering(postref)
    }
  },[handleHovering,index,active])
  return(
    <a ref={postref}  href={link} target="_blank" rel="noreferrer" className='group text-left my-1 first:mt-0 last:mb-0'>
      <h4 className='text-gray-400 italic'>{date}</h4>
      <h3 className='font-bold text-lg group-hover:underline capitalize'>{title}</h3>
      <p className='text-sm h-10 overflow-hidden'>{para}</p>
    </a>

  )
}

const TokenInfo = ({img,title,desc,btn,colored,wide}:{img:string,title:string,desc:string,btn:string,colored?:boolean,wide?:boolean}) => {
  return <div className="w-full md:w-1/2 px-8 text-white flex flex-col md:flex-row md:flex-wrap justify-center items-center">
    <div className="w-full h-40 md:h-64 flex justify-center items-center">
      <img
        src={img}
        alt={title}
        className={`${colored ? 'logolog' : ''} ${wide ? 'h-36 w-30 md:h-48 md:w-80' : 'h-36 w-30 md:h-52 md:w-64'}`} />
    </div>
    <div className="w-full lg:h-[19rem] bg-main rounded-lg p-8 ">
      <div className="text-2xl md:text-4xl bold my-2 font-serif ">{title}</div>
      <div className="text-sm md:text-lg text-gray-300 h-24 mb-2">{desc}</div>
      <div className='flex justify-center'>
        <Button variant={4} className="mt-4 px-20 text-center">{btn}</Button>
      </div>
    </div>
  </div>;
}


const CoinDisplay = ({id,name,img,title,desc,symbol,selectedToken , setSelectedToken,link}:{id:number,name:string , link:string, img:string , title:string , desc:string,symbol:string,selectedToken:1|2 , setSelectedToken:Function}) =>{
  return selectedToken === id ?( 
    <div id={name} className='flex my-4 sm:my-0 justify-center items-center flex-row flex-wrap group w-full md:w-1/2 min-h-96 overflow-hidden border-sub-a'>
      <div className='flex flex-wrap text-center justify-center items-center'>
        <img
          className='w-42 h-40 translate-y-0 transition-transform duration-500 ease-out'
          src={img}
          alt={`${name} Token`}
        />
        <div className=' translate-y-0 flex transition-[opacity,visibility,transform] duration-500 justify-center items-center w-full'>
          <div className='px-6 max-w-lg text-white text-center'>
            <div className='text-2xl md:text-4xl font-bold capitalize'>{name}</div>
            <div className='text-lg md:text-1xl italic text-gray-400'>{title}</div>
            <p >{desc}</p>
            <a href={link} target="_blank" rel='noreferrer' >
              <Button onClick={()=>setSelectedToken(id)} className='mt-2 uppercase' variant={4}>
                  ${symbol}
              </Button>             
            </a>
          </div>
        </div>
      </div>
    </div>
  ):
  (
    <div className='flex my-4 sm:my-0 justify-center items-center flex-row flex-wrap group w-full md:w-1/2 min-h-96 overflow-hidden border-sub-a'>
    <div className='flex flex-wrap text-center justify-center items-center'>
      <img
        className='w-42 h-40 md:translate-y-2/4 group-hover:translate-y-0 transition-transform duration-500 ease-out'
        src={img}
        alt={`${name} Token`}
      />
      <div className='md:translate-y-full group-hover:translate-y-0 md:invisible md:opacity-0 group-hover:visible ease-out group-hover:opacity-100 group-hover:flex flex transition-[opacity,visibility,transform] duration-500 justify-center items-center w-full'>
        <div className='px-6 max-w-lg text-white text-center'>
          <div className='text-2xl md:text-4xl font-bold capitalize'>{name}</div>
          <div className='text-lg md:text-1xl italic text-gray-400'>{title}</div>
          <p >{desc}</p>
          <Button onClick={()=>setSelectedToken(id)} className='mt-2 uppercase' variant={4}>
              ${symbol}
          </Button>             
        </div>
      </div>
    </div>
  </div>
  )}

type sliderProp = {
  id:number;
  image:string;
  title:string;
  text:string;
  btn:string;
  link:string;
  linktype:number;
}


const Slider = ({title,text,btn,link,linktype,image}:sliderProp)=> {
  return(
        <div className='group w-full overflow-hidden h-screen relative'>
          <div className='flex justify-center items-center flex-row h-full w-full z-40 absolute top-0 left-0 right-0 bottom-0 flex-wrap'>
            <div className='flex flex-wrap text-center justify-center'>
              <div className=' flex justify-center items-center w-full'>
                <div className='px-6 md:py-0 md:max-w-3xl text-white text-center'>
                  <div className='text-2xl md:text-4xl font-bold '>{title}</div>
                  <p className='my-4'>{text}</p>
                  <Button className='mt-2' variant={4}>
                    {
                      linktype===0 ? 
                      <Link to={link} >
                        {btn}
                      </Link>
                      :
                      <a rel="noreferrer" target={'_blank'} href={link} >
                        {btn}
                      </a>
                    }

                  </Button>              
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-70 bg-sub-a  group-hover:opacity-80 transition-opacity duration-300 h-full w-full -z-10 '>
          </div>
          <img
            className='h-full w-full absolute top-0 right-0 -z-10'
            src={`/assets/game/${image}`}
            alt="clashingblades"  
           />
        </div>
  )
}


const GameHomePage = () =>{
  const [barh,seth] = useState<number>(0)
  const [bartop , setop] = useState<number>(0)
  const [active,setActive]=useState<number>(0)
  const [ newsData , setNewsData ] = useState([])
  const [selectedToken , setSelectedToken] = useState<1 | 2>(2)
  useEffect(()=>{
    document.title = "Battle For Ethernia | Home"
    const getData = async () =>{
      const getNewsData = await axios.get(`/news/recent`)
      setNewsData(getNewsData.data)           
    }
    getData()
  },[])
  const handleHovering = (el : RefObject<HTMLDivElement>)=>{
    let height = barh
    let top = bartop
    height =el.current!.offsetHeight+5
    top = el.current!.offsetTop-2.5
    seth(height)
    setop(top)
  }
  return(
    <section className=''>
      <div className='flex flex-wrap overflow-hidden'>
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          navigation={true}
          loop={true}
          modules={[Autoplay, Navigation]}
          className="w-full h-full flex flex-wrap overflow-hidden"
        >
            {
              sliders.map(slid =>(
                <SwiperSlide key={slid.id}>
                  <Slider  {...slid} />
                </SwiperSlide>
              ))
            }
        </Swiper>        

      </div>
      <div className='homerest content-start'>
        <div className='w-full flex justify-center overflow-hidden relative mt-16 mb-16 lg:mb-12'>
          <iframe
            width="853"
            height="480"
            className=' w-full h-96 lg:h-[28rem] lg:w-[54rem] rounded-md'
            src={`https://www.youtube.com/embed/${'xJfZrubCl14'}?autoplay=1&mute=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>        
        <div className='w-full flex justify-center'>
          <CoinDisplay id={2} selectedToken={selectedToken} setSelectedToken={setSelectedToken} link="https://coinmarketcap.com/currencies/clashingblades/" name="LYKA" title='Currency Token' img='/assets/goldback.png' symbol='lyka' desc='A token that is used as the primary currency in trading in the world of Ethernia.' />
        </div>
        <div className='lg:flex w-full justify-center mt-10'>
          <div className={"flex max-w-screen-xl justify-center flex-wrap"}>
            {
              LykaData.map(data=>(
                <TokenInfo key={data.id} {...data} />
              ))
            }                                                      
          </div>
        </div>        
        <div className="w-full flex justify-center">
          <div className='max-w-screen-2xl w-full text-center text-white px-2 md:px-8 flex flex-wrap justify-center my-8'>
            <h1 className='text-2xl md:text-4xl border-b-2 border-sub-b h-14 px-2 font-sans tracking-widest' >Latest News</h1>
            <div className='w-full flex flex-wrap overflow-hidden p-4 relative items-center'>
              <div className='w-full rounded-md md:w-6/12 h-72 md:h-[28rem] mt-1'>
              <Swiper
                className='w-full h-full rounded-md'
                direction={"vertical"}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}            
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={(swiper)=>setActive(swiper.activeIndex)}
              >
                {
                  newsData.map((post:any)=>(
                    <SwiperSlide key={post.id}  >
                      <a href={post.link} className='block h-full w-full rounded-md' >
                        <img className='h-full w-full block rounded-md' src={post.image} alt={post.title} />
                      </a>
                    </SwiperSlide>
                  ))
                }
                </Swiper>         
              </div>
              <div style={{height:barh,top:bartop}} className={classNames(true ? 'block' : '','w-1 left-0 md:right-1/2 md:left-1/2 ml-1 duration-500 absolute bg-sub-b overflow-hidden transition-[width,height,top] rounded-t-sm rounded-b-sm')} ></div>
              <div className='w-full md:w-6/12 flex flex-wrap content-start my-2 md:my-0 md:px-4'>
                {
                  newsData.map((post:any,index)=>(
                    <NewsPost index={index} key={post.id} active={active} handleHovering={handleHovering} link={post.link} date={post.date} title={post.title} para={post.description} />

                  ))
                }

              </div>

            </div>
          </div>
        </div>   
      </div>

    </section>
  )
}




export default GameHomePage 