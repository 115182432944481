import { useEffect } from 'react'

import Button from '../components/button'

import { TiArrowRight } from 'react-icons/ti'
import { GiBookCover } from 'react-icons/gi'
import { SiSpeedtest } from 'react-icons/si'
import { BsFillPencilFill } from 'react-icons/bs'
import { MdWebAsset,MdAudiotrack } from 'react-icons/md'
import { FaUnity } from 'react-icons/fa'

import { Link } from 'react-router-dom'
import { IconType } from 'react-icons'


import Fade from 'react-reveal/Fade';


const jobs = [
  {icon:MdWebAsset,jobtitle:'UI Artist',pos:1,link:'https://www.linkedin.com/'},
  {icon:BsFillPencilFill,jobtitle:'3D Artist',pos:2,link:'https://www.linkedin.com/'},
  {icon:MdAudiotrack,jobtitle:'Sound Engineer',pos:2,link:'https://www.linkedin.com/'},
  {icon:SiSpeedtest,jobtitle:'Quality Tester',pos:3,link:'https://www.linkedin.com/'},
  {icon:GiBookCover,jobtitle:'Story Writer',pos:1,link:'https://www.linkedin.com/'},
  {icon:FaUnity,jobtitle:'Client-side Developer',pos:1,link:'https://www.linkedin.com/'}
]



const JobView = (props:{icon:IconType,jobtitle:string,pos:number,link:string})=>{
  const { jobtitle , pos , link} = props
  return(
    <div className='w-full md:w-1/2 md:odd:pr-12 md:even:pl-12 mb-8 group'>
      <a href={link} target="_blank" rel="noreferrer"  >
        <div className=' flex flex-col justify-between bg-main h-72 p-8 group-hover:cursor-pointer border-b-2 border-transparent group-hover:border-sub-b rounded-md transition-colors duration-1000'>
          <div className='text-4xl  text-white'><props.icon /></div>
          <div className='text-3xl font-medium group-hover:text-sub-b transition-colors duration-500'>{jobtitle}</div>
          <div className='text-gray-400 group-hover:text-sub-b transition-colors duration-500 '>{pos} {pos > 1 ? 'positions' : 'position'}</div>        
        </div>        
      </a>
    </div>
  )
}


const CareersPage = () =>{
  useEffect(()=>{
    document.title = "Clashing Blades Online | Careers"
  },[])
  return(
    <Fade>
      <section className='homerest text-white flex justify-center'>
        <div className='w-full max-w-screen-xl'>
          <div className='flex flex-wrap w-full flex-col-reverse md:flex-row px-4 md:px-24 py-4 md:py-12 '>
            <div className='w-full md:w-1/2 text-3xl md:text-5xl mt-4 md:mt-0' >
              <span className='font-medium'>
                Careers
              </span>
            </div>
            <div className='w-full md:w-1/2 flex md:items-center' >
              <nav className="bg-grey-light rounded-md w-full">
                <ol className="list-reset flex justify-start md:justify-end">
                  <li><Link to="/" className="text-gray-400 hover:text-sub-b">Home</Link></li>
                  <li><span className="text-sub-b  mx-2">{'>'}</span></li>
                  <li><Link to="/careers" className="text-white hover:text-sub-b">Careers</Link></li>
                </ol>
              </nav>        
            </div>
          </div>
        </div>
        <div className='group w-full overflow-hidden h-96 my-4 relative'>
          <div className='flex justify-center items-center flex-row h-full w-full z-40 absolute top-0 left-0 right-0 bottom-0 flex-wrap'>
            <div className='flex flex-wrap text-center justify-center'>
              <div className='transition-[opacity,visibility] duration-500 flex justify-center items-center w-full'>
                <div className='px-6 md:py-0 text-white text-center'>
                  <div className='text-2xl md:text-4xl font-bold'>Extraordinary starts with you!</div>
                  <Button className='mt-4 rounded-3xl' variant={4}>
                    <a href="https://www.linkedin.com/" className='flex justify-center items-center'>
                      More
                      <TiArrowRight className='text-2xl'/> 
                    </a>
                  </Button>              
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-50 transition-opacity duration-300 h-full w-full -z-10 '>
          <video
            poster='https://webstatic-sea.hoyoverse.com/upload/event/2022/01/24/c78cd794ae4e5623877049ec3cf2973a_7257776737686451913.jpg'
            muted={true}
            playsInline
            autoPlay
            loop
            src={"https://webstatic-sea.hoyoverse.com/upload/op-public/2022/01/26/ea768ab76ccd988e9d3151840ed7629b_1098538695571395003.mp4"}
            className="h-full w-full absolute top-0 right-0 z-10 object-cover"
          ></video>
          </div>
        </div>     
        <div className='max-w-screen-xl w-full flex flex-wrap content-start px-4 md:px-24 py-4 md:py-12'>
          <div className="w-full text-5xl font-medium mb-6" >Available Jobs</div>
          <div className='w-full flex flex-wrap content-start'>
            {
              jobs.map((job,index)=>(
                <JobView key={index} {...job} />
              ))
            }
          </div>
        </div>      
      </section>
    </Fade>
  )
}


export default CareersPage