import React,{ createContext , useCallback , useEffect } from 'react';

import { useSessionStorage } from '../hooks/useSessionStorage'
// import { isObjectEqual } from '../hooks/Utils'
import { useWeb3React } from '@web3-react/core'

export const profileContext = createContext();

export default function Profile({ children }) {
  const [profile, setProfile] = useSessionStorage("CLASHINGBLADES_PROFILE", {id:0,email:'',wallet:''})
  const { deactivate,} = useWeb3React()

  const conWallet = useCallback(
     (wallet)=>{
      setProfile({id:0,email:'',wallet:wallet})
  },[setProfile])

  const updateProfile = useCallback(
    async (user)=>{
      setProfile(user)
    },[setProfile])

  const signout = () =>{
    setProfile({id:0,email:'',wallet:''})
    deactivate()
  }
  const resetProfile = ()=>{
    setProfile({id:0,email:'',wallet:''})
    if(typeof window !== "undefined"){
      window.localStorage.setItem("CLASHINGBLADES_PROFILE", JSON.stringify({id:0,email:'',wallet:''}));
    }
    
  }
  useEffect(()=>{

   },[])

  //  function shallowEqual(object1, object2) {
  //   const keys1 = Object.keys(object1);
  //   const keys2 = Object.keys(object2);
  //   if (keys1.length !== keys2.length) {
  //     return false;
  //   }
  //   for (let key of keys1) {
  //     if (object1[key] !== object2[key]) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }  

  return (
    <profileContext.Provider value = {{profile , resetProfile,conWallet ,updateProfile,signout }}>
      {children}  
    </profileContext.Provider>
  );
}

