import React, { useState } from 'react';

import { Routes, Route } from "react-router-dom";




//Clashing Blade Pages
import HomePage from './pages/home';
import NewsPage from './pages/news';
import CareersPage from './pages/careers';
import RegisterPage from './pages/register';
import AboutPage from './pages/about';

//Battle For Ethernia
import GameHomePage from './pages/bte'
import Gameplay from './pages/bte/gameplay';
import EconomyPage from './pages/bte/gameplay/economy';
import StatsPage from './pages/bte/gameplay/stats';
import EquipmentPage from './pages/bte/gameplay/equipments';
import ClassesPage from './pages/bte/gameplay/classes';
import NobilityPage from './pages/bte/gameplay/nobility';
import MonstersPage from './pages/bte/gameplay/monsters';
import Navbar from './components/navbar';
import Footer from './components/footer';

//Extra Pages
import Loading from './pages/loading';


import axios from "axios";

//change axios url
axios.defaults.baseURL = process.env.NODE_ENV === "development" 
? "https://apii.clashingblades.com" 
: "https://apii.clashingblades.com"
axios.defaults.withCredentials = false;


function App() {
  const [loading ,setLoading] = useState<boolean>(true)


  return (
    <div className={loading ? 'h-screen overflow-hidden' : ''}>
      {
        loading ? <Loading setLoading={setLoading} /> : null
      }
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/register" element={<RegisterPage/> } />
        <Route path="/about" element={<AboutPage /> } />    
        <Route path="/bte" element={<GameHomePage/>}/>
        <Route path="/bte/gameplay" element={<Gameplay/>}/>
        <Route path="/bte/gameplay/economy" element={<EconomyPage/>}/>
        <Route path="/bte/gameplay/stats" element={<StatsPage/>}/>
        <Route path="/bte/gameplay/equipments" element={<EquipmentPage/>}/>
        <Route path="/bte/gameplay/classes" element={<ClassesPage/>}/>
        <Route path="/bte/gameplay/nobility" element={<NobilityPage/>}/>
        <Route path="/bte/gameplay/monsters" element={<MonstersPage/>}/>                                         
      </Routes>      
      <Footer/>      
    </div>    
  )
}

export default App;
