import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../components/button'


import { profileContext } from '../contexts/account'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { InjectedConnector, UserRejectedRequestError } from "@web3-react/injected-connector";

import axios from 'axios'



const Injected = new InjectedConnector({
  supportedChainIds: [
    1, 
    56
]
 });

const RegisterPage  = () =>{
  const [username,setUsername] = useState<string>("")
  const [password,setPassword] = useState<string>("")
  const [cpassword,setCPassword] = useState<string>("")
  const [walletAddress,setWalletAddress] = useState<string>("")  
  const [alertController , setAlertController] = useState<{type:0|1|2,message:string}>({type:0,message:""})
  const navigate = useNavigate()
  const { conWallet , profile } = useContext(profileContext)
  const { account, activate, setError, active } = useWeb3React<Web3Provider>()


  useEffect(()=>{
    document.title = "Clashing Blades Online | Signup"
    if(profile.id){
			navigate('/profile')
		}
  },[profile.id,navigate])
  useEffect(()=>{
    if(account){
      conWallet(account)
      setWalletAddress(account)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[account])

  const handleSignUp = (e:React.MouseEvent<HTMLElement>)=>{
    e.preventDefault()
    if(password.length < 8){
			setAlertController({type:2,message:'Your password must be 8 characters or more'})
		}else if(username.length < 6){
			setAlertController({type:2,message:'Your username must be 6 characters or more'})      
		}else if(password !== cpassword){
			setAlertController({type:2,message:'Password and confirm pasword are not the same'})
		}else if(walletAddress.length < 41){
			setAlertController({type:2,message:'Your charname must be 5 characters or more'})
		}
		else{
			axios.post('https://api.clashingblades.com/register',{
				username:username,
				password:password,
        wallet:walletAddress
			})
			.then(resp =>{
				setAlertController({type:1,message:'Your account has been created successfully'})
			})
			.catch(err =>{
				console.log(err)
        if(err.response.data){
          setAlertController({type:2,message:err.response.data})
        }else{
          setAlertController({type:2,message:"Something went wrong , please try again later"})
          
        }
		})			
		}
  }

  const onClickConnect = () => {
    activate(Injected,(error) => {
      if (error instanceof UserRejectedRequestError) {
        // ignore user rejected error
        console.log("user refused")
      } else {
        setError(error)
      }
    }, false)
  }

  // const onClickDisconnect = () => {
  //   deactivate()
  //   resetProfile()
  // }

  return(
    <section className='flex items-center justify-center homerest text-white'>
      <div className='flex items-center justify-center w-full h-full m-4 my-8 lg:m-16'>
        <div className='bg-sub-a min-h-[40rem] w-full xl:w-[50rem] 2xl:w-[60rem] rounded-md p-4 py-8 lg:p-12'>
          <div className="z-10 w-full flex justify-center h-[150px]">
            <img src="/assets/logo.png" alt="Logo" width={250} height={250} />
          </div>
          <h3 className="font-bold text-2xl text-sub-b">Welcome to Clashing Blades</h3>
          {
            active ? 
            <div>
              <p className="text-gray-300 pt-2">Create your account.</p>
              <div className="mt-10">
                <form className="flex flex-col">
                  <input required onChange={(e)=>setUsername(e.target.value)} value={username} className="w-full mb-3 p-2.5 pl-3 rounded-md text-sub-a focus-within:text-sub-b  outline-none" type="text" placeholder='Username' />         
                  <input required onChange={(e)=>setPassword(e.target.value)} value={password} className="w-full mb-3 p-2.5 pl-3 rounded-md text-sub-a focus-within:text-sub-b  outline-none"  type="password" placeholder='Password'/>         
                  <input required onChange={(e)=>setCPassword(e.target.value)} value={cpassword}  className="w-full mb-3 p-2.5 pl-3 rounded-md text-sub-a focus-within:text-sub-b  outline-none" type="password" placeholder='Confirm Password' />         
                  <input required onChange={(e)=>setWalletAddress(e.target.value)} value={walletAddress}  className="w-full mb-3 p-2.5 pl-3 rounded-md text-sub-a focus-within:text-sub-b  outline-none" type="text" placeholder='Wallet Address' />         
                  <div className='flex justify-center'>
                    <Button variant={4}  className='mt-8 w-64' onClick={handleSignUp}>Sign UP</Button>            
                  </div>  
                  <div className={`${alertController.type === 1 ? 'bg-green-400' : alertController.type === 2 ? 'bg-red-500' :'hidden' } rounded-lg mt-8 p-2 flex items-center`}>
                    <span className='text-2xl flex text-center justify-center items-center mr-2'>
                      {
                        alertController.type === 1 ?(
                          "O"
                        ) :(
                          "X"
                        )
                      }
                    </span>
                    {alertController.message}
                  </div>                                           
                </form>
              </div>                
            </div>
            :
            <div>
              <p className="text-gray-300 pt-2">Connect to your wallet.</p>
              <div className="flex flex-wrap mt-10">
                <div className='w-full'>
                  <Button onClick={onClickConnect} className='w-full my-4'>MetaMask</Button>
                </div>
              </div> 
            </div>
          }
        
        </div>
      </div>
    </section>
  )
}


export default RegisterPage 