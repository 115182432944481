import React,{ useEffect } from 'react'



import { Link } from 'react-router-dom'

const data = [
  {id:1,title:'Monters',img:'/assets/game/slider01.jpg',desc:'As one adventures in the world of Ethernia, they will find different kinds of beasts that grow stronger as they reach higher ranks.'},
  {id:2,title:'Monster Ranks',img:'/assets/game/slider02.jpg',desc:'1. Normal\n2. Elite\n3. Champion\n4. Hero\n5. King\n'},
  {id:3,title:'Kings',img:'/assets/game/slider03.jpg',desc:'King is the highest Rank a monster can have, it has almost Twenty thousand percent more health than a Normal monster, and the drop rates are 300 times more with a meagre spawn rate making these monsters almost scarce; they rarely show up.'},
]

const DataDisplay = ({title,img,desc,index}:{title:string,img:string,desc:string,index:number}) =>{
  const type = index % 2
  return(
    <div className='flex flex-wrap justify-center md:justify-start even:flex-row odd:flex-row-reverse w-full relative my-16  ' >
      <div className='bg-sub-a w-full md:w-8/12 h-96 md:h-[30rem] rounded-lg'>
        <img
        alt='data'
        src={img}
        className='h-full w-full '
        />
      </div>
      <div className={`${type ? 'right-8' : 'left-8'} -mt-8 w-10/12 md:absolute md:w-2/5 h-[26rem] -top-8`}>
        <div className='border bg-main border-sub-b h-full w-full borderd rounded-lg px-8 py-12 '>
          <div className='text-4xl font-medium text-center mb-4'>{title}</div>
          <div className='text-xl text-gray-300 tracking-wide display-linebreak'>{desc}</div>
        </div>
      </div>
    </div>    
  )
}



const MonstersPage = () =>{
  useEffect(()=>{
    document.title = "Clashing Blades Online | Gameplay | Economy"
  },[])
  return(
    <section className='homerest text-white'>
      <div className='group w-full overflow-hidden h-96 md:h-[40rem] relative'>
        <div className='flex justify-center items-center flex-row h-full w-full z-40 absolute top-0 left-0 right-0 bottom-0 flex-wrap'>
          <div className='flex flex-wrap text-center justify-center'>
            <div className='transition-[opacity,visibility] duration-500 flex justify-center items-center w-full'>
              <div className='px-6 md:py-0 text-white text-center'>
                <div className='text-2xl md:text-4xl uppercase font-serif font-medium tracking-widest'>The nature protectors</div>
                <div className='flex flex-wrap justify-center w-full mt-2'>
                  <div className='h-0.5 w-4/12 bg-sub-a mx-2 my-2 rounded-full' />
                  <div className='h-0.5 w-8/12 bg-sub-a mx-2 my-2 rounded-full' />
                  <div className='h-0.5 w-full bg-sub-a mx-2 my-2 rounded-full' />

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='absolute bg-sub-a h-full w-full z-20 opacity-40'>

        </div> 
        <div className=' transition-opacity duration-300 h-full w-full -z-10 '>
          <img
            src={`/assets/game/slider01.jpg`}
            alt="cover"
            className="h-full w-full absolute top-0 right-0 z-10 object-cover"
          />
        </div>

      </div>
      <div className='flex flex-wrap w-full flex-col-reverse md:flex-row px-4 md:px-24 py-4 md:py-12 '>
        <div className='w-full md:w-1/2 text-3xl md:text-5xl mt-4 md:mt-0' >
          <span className='font-medium'>
            Monsters
          </span>
        </div>
        <div className='w-full md:w-1/2 flex md:items-center' >
          <nav className="bg-grey-light rounded-md w-full">
            <ol className="list-reset flex justify-start md:justify-end">
              <li><Link to="/bte" className="text-gray-400 hover:text-sub-b">Home</Link></li>
              <li><span className="text-sub-b  mx-2">{'>'}</span></li>
              <li><Link to="/bte/gameplay" className="text-gray-400  hover:text-sub-b">Gameplay</Link></li>
              <li><span className="text-sub-b  mx-2">{'>'}</span></li>
              <li><Link to="/bte/gameplay/monsters" className="text-white hover:text-sub-b">Monsters</Link></li>

            </ol>
          </nav>        
        </div>
      </div>
      <div className='w-full flex justify-center'>
        <div className='max-w-7xl flex flex-wrap w-full px-8 '>
          {
            data.map((dat,index)=>(
              <DataDisplay key={dat.id} index={index} {...dat} />
            ))
          }        
        </div>
      </div>
    </section>
  )
}


export default MonstersPage 