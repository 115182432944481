import React from 'react'


export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, React.AriaAttributes  {}

export interface ButtonProps2 extends ButtonProps {
  variant?:number
}

const Button = (props:ButtonProps2)=>{
  const {children,className,variant,...rest} = props  
  return variant === 1 ? (
    <button {...rest} className={className + ` text-white rounded-sm border-2 border-transparent bg-main uppercase py-2 px-8 hover:bg-transparent hover:border-main hover:text-main transition-colors duration-400`}>
      {children}
    </button>
  ):
  variant === 2 ? (
    <button {...rest} className={className + ` text-white rounded-sm border-2 border-transparent bg-main uppercase py-2 px-8 hover:bg-transparent hover:border-main hover:text-main transition-colors duration-400`}>
      {children}
    </button>
  ):
  variant === 3 ? (
    <button {...rest} className={className + ` text-sub-b rounded-sm border-2 border-transparent bg-sub-a uppercase py-2 px-8 hover:bg-transparent hover:border-sub-a hover:text-sub-a transition-colors duration-400`}>
      {children}
    </button>
  ):
  (
    <button {...rest} className={className + ` text-sub-a rounded-sm border-2 border-transparent bg-sub-b uppercase py-2 px-8 hover:bg-transparent hover:border-sub-b hover:text-sub-b transition-colors duration-400`}>
      {children}
    </button>
  )

}




export default Button