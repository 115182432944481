import React from 'react'

import { Link } from 'react-router-dom'
import { IconType } from 'react-icons/lib';
import { FaFacebookF,FaDiscord } from "react-icons/fa"
import { BsYoutube,BsTwitter,BsTelegram } from "react-icons/bs"



import Button from './button'

const socialinks=[
  {id:1,link:'https://www.facebook.com/',icon:FaFacebookF},
  {id:2,link:'https://www.youtube.com/c/ClashingBlades',icon:BsYoutube},
  {id:3,link:'https://www.twitter.com/clashingblades',icon:BsTwitter},
  {id:4,link:'https://t.me/clashingblades',icon:BsTelegram},
  {id:5,link:'https://www.discord.com/',icon:FaDiscord}
]


const FootLink = ({name,to}:{name:string,to:string}) =>{
  return(
    <div className='px-2 text-white my-1 first:pl-0 border-r-2 border-sub-b last:border-r-0 md:text-sm text-xs'>
      <Link to={to}>
        {name}    
      </Link>
    </div>
  )
}

const SocialLink = (props:{link:string,icon:IconType}) =>{
  return(
    <div className='text-xl mx-2 text-white'>
      <a href={props.link}>
        <props.icon />
      </a>
    </div>
  )
}


const Footer = () =>{
  return(
    <footer className='px-4 py-2 cb-footer flex flex-wrap overflow-hidden md:px-12 md:py-8'>
      <div className="w-full md:w-1/2 overflow-hidden self-start flex flex-wrap md:justify-start">
        <FootLink name="Vist Us" to="/" />
        <FootLink name="Contact Us" to="/" />
        <FootLink name="Privacy Police" to="/" />
        <FootLink name="Clashing Blades" to="/" />
        <FootLink name="Lyka" to="/" />
      </div>

      <div className="w-full md:w-1/2 min-h-8 overflow-hidden flex md:justify-end justify-start border-t border-sub-b pt-4 mt-2 md:border-t-0 md:pt-0 md:mt-0 border-opacity-40">
        {
          socialinks.map((link)=>(
            <SocialLink link={link.link} icon={link.icon} key={link.id} />
          ))
        }
      </div>
      <div className="w-full text-white md:w-1/2 overflow-hidden md:border-t border-sub-b mt-4 md:pt-8 pt-2">
        <Link to='/'>
          <img src="/assets/logo.png" alt="Clashingblades" width={180} height={60} />
        </Link>
        <div>© 2021 ClashingBlades LLC. All rights reserved.</div>
      </div>
      <div className="w-full md:w-1/2 overflow-hidden md:border-t flex md:justify-end justify-start border-sub-b md:mt-4 md:pt-8 mt-2 pt:2">
      <Button variant={4} className="h-12">
        Subscribe
      </Button>
      </div>

    </footer>
  )
}


export default Footer