import React,{ useEffect, useState } from 'react'

import axios from 'axios'

import { Link } from 'react-router-dom'




import Fade from 'react-reveal/Fade';


const NewsView = ({link,date,title,image,description}:{link:string,date:string,title:string,image:string,description:string})=>{
  return(
    <div className='w-full md:w-1/2 md:odd:pr-12 md:even:pl-12 mb-8 group'>
      <a href={link} target="_blank" rel="noreferrer"  >
        <div className=' flex flex-col justify-between bg-sub-a h-72 overflow-hidden group-hover:cursor-pointer border-b-2 border-transparent group-hover:border-sub-b rounded-lg transition-colors duration-500'>
          <img src={image} className="h-full w-full " alt="news" />
        </div>      
        <div className='text-gray-500 ml-1'>{date}</div>        
        <div className='text-lg font-medium mb-4 group-hover:underline  '>{title}</div>
        <p className='text-sm h-24'>{description}</p>

      </a>
    </div>
  )
}


const NewsPage = () =>{
  const [ newsData , setNewsData ] = useState([])
  useEffect(()=>{
    document.title = "Clashing Blades Online | News"
    const getData = async () =>{
      const getData = await axios.get(`/news/all`)
      setNewsData(getData.data)
    }
    getData()
  },[])
  return(
    <Fade>
      <section className='homerest text-white flex justify-center'>
        <div className='max-w-screen-xl'>
          <div className='flex flex-wrap w-full flex-col-reverse md:flex-row px-4 md:px-24 py-4 md:py-12 '>
            <div className='w-full md:w-1/2 text-3xl md:text-5xl mt-4 md:mt-0' >
              <span className='font-medium'>
                News
              </span>
            </div>
            <div className='w-full md:w-1/2 flex md:items-center' >
              <nav className="bg-grey-light rounded-md w-full">
                <ol className="list-reset flex justify-start md:justify-end">
                  <li><Link to="/" className="text-gray-400 hover:text-sub-b">Home</Link></li>
                  <li><span className="text-sub-b  mx-2">{'>'}</span></li>
                  <li><Link to="/news" className="text-white hover:text-sub-b">News</Link></li>
                </ol>
              </nav>        
            </div>
          </div>
          <div className='w-full flex flex-wrap justify-center content-start px-4 md:px-24 py-4 md:py-12'>
            {
              newsData.map((news:any)=>(
                <NewsView key={news.id} {...news} />
              ))
            }
          </div>
        </div>
      </section>
    </Fade>
  )
}


export default NewsPage