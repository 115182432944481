import React , { useEffect,RefObject, useRef,useState } from 'react'
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper";

import { FaFacebook, FaInstagram, FaLinkedin, FaTelegram, FaTwitter } from "react-icons/fa"

import Button from '../components/button'



import axios from 'axios';



import Fade from 'react-reveal/Fade';




const partnersData = [
  {id:1,color:false,name:"Contract Wolf",image:'https://imgur.com/6nQzRAq.png',link:'https://contractwolf.io/projects/clashing-blades'},
  {id:2,color:true,name:"GamePad",image:'https://imgur.com/TxLkmJO.png',link:'https://gempad.app/'},
  {id:3,color:true,name:"Coinmarketcap",image:'https://imgur.com/mc1Ahjc.png',link:'https://coinmarketcap.com/currencies/clashingblades/'},
]

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

const NewsPost = ({index,link,date,title,para,handleHovering,active}:{index:number,active:number,link:string,date:string,title:string,para:string,handleHovering:Function})=>{
  const postref = useRef<HTMLAnchorElement|null>(null)
  useEffect(()=>{
    if(index===active){
      handleHovering(postref)
    }
  },[handleHovering,index,active])
  return(
    <a ref={postref}  href={link} target="_blank" rel="noreferrer" className='group text-left my-1 first:mt-0 last:mb-0'>
      <h4 className='text-gray-400 italic'>{date}</h4>
      <h3 className='font-bold text-lg group-hover:underline capitalize'>{title}</h3>
      <p className='text-sm h-10 overflow-hidden'>{para}</p>
    </a>

  )
}

type teamProps ={
  key:number;
  name:string;
  jtitle:string;
  image:string;
  social:string
}

const TeamMember = ({name,jtitle,image,social}:teamProps)=>{
  const SocialArray=social.split(',')
  return(
    <div className='min-h-96 md:h-128 w-1/2 xl:w-1/3 2xl:w-1/4 px-2 overflow-hidden md:px-8 my-2 flex justify-center flex-wrap content-start'>
      <img className='h-52 md:h-[20rem] object-cover rounded-xl w-full ' src={image}  alt={name} />
      <div className='text-left w-full'>
        <div className='font-bold text-white my-1'>{name}</div>
        <div className='text-sub-b my-1'>{jtitle}</div>
        <div className='flex my-1'>
          <div className={`${SocialArray[0]==="#" ? "hidden" : ''} text-xl mx-2 first:ml-0`}>
            <a href={SocialArray[0]} rel='noreferrer' target="_blank">
              <FaFacebook />
            </a>
          </div>  
          <div className={`${SocialArray[1]==="#" ? "hidden" : ''} text-xl mx-2 first:ml-0`}>
            <a href={SocialArray[1]} rel='noreferrer' target="_blank">
              <FaTwitter />
            </a>
          </div>  
          <div className={`${SocialArray[2]==="#" ? "hidden" : ''} text-xl mx-2 first:ml-0`}>
            <a href={SocialArray[2]} rel='noreferrer' target="_blank">
              <FaInstagram />
            </a>
          </div>  
          <div className={`${SocialArray[3]==="#" ? "hidden" : ''} text-xl mx-2 first:ml-0`}>
            <a href={SocialArray[3]} rel='noreferrer' target="_blank">
              <FaTelegram />
            </a>
          </div>  
          <div className={`${SocialArray[4]==="#" ? "hidden" : ''} text-xl mx-2 first:ml-0`}>
            <a href={SocialArray[4]} rel='noreferrer' target="_blank">
              <FaLinkedin />
            </a>
          </div>  



        </div>        
      </div>
    </div>
  )
}










type sliderProp = {
  id:number;
  bgImage:string;
  name:string;
  desc:string;
  link:string;
  done:boolean;
  linktype:number;
}


const Slider = ({name,desc,link,linktype,bgImage,done}:sliderProp)=> {
  return(
        <div className='group w-full overflow-hidden h-screen relative'>
          <div className='flex justify-center items-center flex-row h-full w-full z-40 absolute top-0 left-0 right-0 bottom-0 flex-wrap'>
            <div className='flex flex-wrap text-center justify-center'>
              <div className=' flex justify-center items-center w-full'>
                <div className='px-6 md:py-0 md:max-w-3xl text-white text-center'>
                  <div className='text-2xl md:text-4xl font-bold '>{name}</div>
                  <p className='my-4'>{desc}</p>
                  {
                  done ?
                  <Button className='mt-2' variant={4}>
                    {
                      !linktype ? 
                      <Link to={link} >
                        Learn More
                      </Link>
                      :
                      <a rel="noreferrer" target={'_blank'} href={link} >
                         Learn More
                      </a>
                    }

                  </Button> 
                  :
                  <Button className='mt-2' variant={4}>
                    Coming Soon!
                  </Button> 
                }            
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-70 bg-sub-a  group-hover:opacity-80 transition-opacity duration-300 h-full w-full -z-10 '>
          </div>
          <img
            className='h-full w-full absolute top-0 right-0 -z-10'
            src={bgImage}
            alt="clashingblades"  
           />
        </div>
  )
}


const ProjectCard = ({name,desc,link,linktype,bgImage,done}:sliderProp)=>{
  return(
    <div className="card w-10/12 lg:w-4/12 h-96 my-4 bg-base-100 shadow-xl image-full group">
      <figure><img src={bgImage} alt="Shoes" /></figure>
      <div className="card-body justify-between">
        <h2 className="card-title text-sub-b uppercase text-2xl justify-center">{name}</h2>
        <div className='self-center justify-self-center '>{desc}</div>
        <div className="card-actions justify-center">
        {
          done ?
          <Button className='mt-2' variant={4}>
            {
              !linktype ? 
              <Link to={link} >
                Learn More
              </Link>
              :
              <a rel="noreferrer" target={'_blank'} href={link} >
                  Learn More
              </a>
            }

          </Button> 
          :
          <Button className='mt-2' variant={4}>
            Coming Soon!
          </Button> 
        }   
        </div>
      </div>
    </div>
  )
}


const HomePage = () =>{
  const [barh,seth] = useState<number>(0)
  const [bartop , setop] = useState<number>(0)
  const [active,setActive]=useState<number>(0)
  const [ newsData , setNewsData ] = useState([])
  const [ teamData , setTeamData ] = useState([])
  const [ projectsData , setProjectsData ] = useState([])

  useEffect(()=>{
    document.title = "Clashing Blades Online | Home"
    const getData = async () =>{
      const getNewsData = await axios.get(`/news/recent`)
      setNewsData(getNewsData.data)
      const getTeamData = await axios.get(`/team/all`)
      setTeamData(getTeamData.data) 
      const getProjectsData = await axios.get(`/projects/all`)
      setProjectsData(getProjectsData.data)              
    }
    getData()
  },[])
  const handleHovering = (el : RefObject<HTMLDivElement>)=>{
    let height = barh
    let top = bartop
    height =el.current!.offsetHeight+5
    top = el.current!.offsetTop-2.5
    seth(height)
    setop(top)
  }
  return(
    <Fade>
      <section className=''>
        <div className='flex flex-wrap overflow-hidden -z-20'>
          <Swiper
            spaceBetween={0}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            navigation={true}
            loop={true}
            modules={[Autoplay, Navigation]}
            className="w-full h-full flex flex-wrap overflow-hidden"
          >
              {
                projectsData &&
                projectsData.map((pj:any) =>(
                  <SwiperSlide key={pj.id}>
                    <Slider  {...pj} />
                  </SwiperSlide>
                ))
              }
          </Swiper>        

        </div>
        <div className='hidden relative -mt-16 z-50 mb-20'>
          <img  className='absolute bg-transparent' alt="sand" src='assets/sand.png'/>
        </div>
        <div className='homerest content-start'>

          <div className="w-full flex justify-center">
            <div className='max-w-screen-2xl w-full text-center text-white px-2 md:px-16 flex flex-wrap justify-center my-8'>
              <h1 className='text-2xl md:text-4xl border-b-2 border-sub-b h-14 px-2 font-sans tracking-widest' >Our Projects</h1>
              <div className='w-full flex flex-wrap my-6 justify-around'>
                {
                  projectsData && (
                  projectsData.map((pj:any)=>(
                    <ProjectCard key={pj.id} {...pj} />
                  )))
                }
              </div>
            </div>

          </div>         
          <div className="w-full flex justify-center">
            <div className='max-w-screen-2xl w-full text-center text-white px-2 md:px-8 flex flex-wrap justify-center my-8'>
              <h1 className='text-2xl md:text-4xl border-b-2 border-sub-b h-14 px-2 font-sans tracking-widest' >Latest News</h1>
              <div className='w-full flex flex-wrap overflow-hidden p-4 relative items-center'>
                <div className='w-full rounded-md md:w-6/12 h-72 md:h-[28rem] mt-1'>
                <Swiper
                  className='w-full h-full rounded-md'
                  direction={"vertical"}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}            
                  spaceBetween={50}
                  slidesPerView={1}
                  onSlideChange={(swiper)=>setActive(swiper.activeIndex)}
                >
                  {
                    newsData.map((post:any)=>(
                      <SwiperSlide key={post.id}  >
                        <a href={post.link} className='block h-full w-full rounded-md' >
                          <img className='h-full w-full block rounded-md' src={post.image} alt={post.title} />
                        </a>
                      </SwiperSlide>
                    ))
                  }
                  </Swiper>         
                </div>
                <div style={{height:barh,top:bartop}} className={classNames(true ? 'block' : '','w-1 left-0 md:right-1/2 md:left-1/2 ml-1 duration-500 absolute bg-sub-b overflow-hidden transition-[width,height,top] rounded-t-sm rounded-b-sm')} ></div>
                <div className='w-full md:w-6/12 flex flex-wrap content-start my-2 md:my-0 md:px-4'>
                  {
                    newsData.map((post:any,index)=>(
                      <NewsPost index={index} key={post.id} active={active} handleHovering={handleHovering} link={post.link} date={post.date} title={post.title} para={post.description} />

                    ))
                  }

                </div>

              </div>
            </div>
          </div>   
          <div className="w-full flex justify-center">
            <div className='max-w-screen-2xl w-full text-center text-white px-2 md:px-16 flex flex-wrap justify-center my-8'>
              <h1 className='text-2xl md:text-4xl border-b-2 border-sub-b h-14 px-2 font-sans tracking-widest' >Our Team</h1>
              <div className='w-full flex flex-wrap my-6 justify-around'>
                {
                  teamData.map((member:any)=>(
                    <TeamMember key={member.id} {...member} />
                  ))
                }
              </div>
            </div>

          </div> 
          <div className='w-full text-center text-white px-2 md:px-16 flex flex-wrap justify-center my-8'>
            <h1 className='text-2xl md:text-4xl border-b-2 border-sub-b h-14 px-2 font-sans tracking-widest' >Our Partners</h1>
            <div className='w-full flex flex-wrap my-6 justify-around'>
              {
                partnersData.map((partner:any)=>(
                <div key={partner.id} className="w-6/12 lg:w-3/12 flex justify-center">
                  <a href={partner.link} target="_blank" rel='noreferrer'>
                    <img alt={partner.name} src={partner.image} className={`${partner.color ? 'logolog' : ''} h-32 lg:h-48 `} />

                  </a>
                </div>
                ))
              }
            </div>
          </div>

        </div>
        
      </section>
    </Fade>
  )


  }


export default HomePage