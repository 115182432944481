import { createContext, useContext } from 'react';




import Profile from './account'

// import { useSessionStorage } from '../hooks/useSessionStorage'


const AppContext = createContext();


export function AppWrapper({ children }) {
  const lol = []
  // const [sessionState , setSessionState] = useSessionStorage("sessionState",{})
  // const [sharedState,setSharedState] = useState(sessionState)
  // useEffect(()=>{
  //   setSessionState(sharedState)
  // },[sharedState,setSessionState])
  return (
    <AppContext.Provider value={lol}>
        <Profile>
          {children}
        </Profile>
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}