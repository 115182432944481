import { useEffect } from 'react';


export default function Loading({setLoading}:{setLoading:Function}) {
  useEffect(()=>{
      setTimeout(()=>{
        setLoading(false)
      },4000)
      
  },[setLoading])
  return (
    <div className="bg-sub-a h-screen w-screen flex justify-center items-center flex-wrap flex-col text-white">
      <div className="bg-transparent w-64 h-64 ">
        <div className="relative w-full h-full text-center transition-transform duration-700 ">
          <div className="z-10 absolute w-full h-full">
            <img src="assets/logo.png" alt="Logo" width={400} height={300} />
            
          </div>

        </div>
      </div>
      <div className="mt-16 w-10/12 h-5 border rounded-lg border-sub-b overflow-hidden">
        <div className="rounded-lg bg-sub-b h-full loading-fill "></div>
      </div>
      <div className="mt-12 text-center uppercase text-xl max-w-6xl">
        ClashingBlades Online
      </div>
  </div> 
  )
}