import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import Fade from 'react-reveal/Fade';

const AboutValue = ({title,desc}:{title:string,desc:string}) =>{
  return(
    <div className='w-full flex flex-wrap my-6 justify-center'>
      <div className='w-4/12 text-xl uppercase md:text-4xl font-medium'>
        {title}
      </div>
      <div className='w-8/12 text-lg md:text-xl'>
        {desc}
      </div>
    </div>
  )
}


const values = [
  {title:'Why',desc:'We believe games on the blockchain will become the future of gaming.'},
  {title:'How',desc:'Based on our creativity, steadfast dedication towards new challenges and technological development,'},
  {title:'What',desc:'Ethernia is our game\'s universe where all our games will be based on; some information you encounter might benefit you later.'},
  {title:'Present',desc:'We will first introduce our ideas in the upcoming 3D MMORPG.'},
  {title:'Future',desc:'We are going to create several games in the upcoming years.'},

]

const AboutPage = () =>{
  useEffect(()=>{
    document.title = "Clashing Blades LLC | About"
  },[])
  return(
    <Fade>
      <section className='homerest text-white'>
          <div className='flex flex-wrap w-full flex-col-reverse md:flex-row px-4 md:px-24 py-4 md:py-12 '>
            <div className='w-full md:w-1/2 text-3xl md:text-5xl mt-4 md:mt-0 ' >
              <span className='font-medium'>
                About us
              </span>
            </div>
            <div className='w-full md:w-1/2 flex md:items-center' >
              <nav className="bg-grey-light rounded-md w-full">
                <ol className="list-reset flex justify-start md:justify-end">
                  <li><Link to="/" className="text-gray-400 hover:text-sub-b">Home</Link></li>
                  <li><span className="text-sub-b  mx-2">{'>'}</span></li>
                  <li><Link to="/about" className="text-white hover:text-sub-b">About</Link></li>
                </ol>
              </nav>        
            </div>
          </div>
          <div className='w-full flex justify-center items-center my-4 h-96 bg-main clip-pp'>
            <img src='assets/logo.png' className='w-56 h-24 md:w-96 md:h-48' alt="clashing blades logo" />
          </div>
          <div className='w-full flex flex-wrap content-start px-4 md:px-24 py-4 md:py-12'>
            <div className="w-full text-5xl font-medium mb-6" >Vision</div>
            {
              values.map((va,index)=>(
                <AboutValue key={index} {...va}  />
              ))
            }
          </div>

      </section>
    </Fade>
  )
}


export default AboutPage 