import React,{ useEffect } from 'react'



import { Link } from 'react-router-dom'

const data = [
  {id:1,title:'The Character',img:'/assets/game/slider01.jpg',desc:'Every game Character has six main stats, and the combination of those stats will determine the damage output and defense of the Character; with every level, Players will acquire five stat points to put towards any of the six available stats.'},
  {id:2,title:'Strength',img:'/assets/game/slider02.jpg',desc:'Determines the Physical damage output and Physical Defense, usually preferred by Characters of the Warrior class.\n 1 Strength = 20 Physical Damage / 5 Physical Defense / 15 Maximum Health Points.'},
  {id:3,title:'Intelligence',img:'/assets/game/slider03.jpg',desc:'Determines the Magical damage output and Magical Defense, which is Great for Mage and MedicineMan.\n1 Intelligence = 20 Magical Damage / 5 Magical Defense / 20 Mana Points'},
  {id:4,title:'Agility',img:'/assets/game/slider02.jpg',desc:'Increases Movement Speed and Casting Speed!\n1 Agility = 1% Movement speed / 0.1% casting speed'},
  {id:5,title:'Vitality',img:'/assets/game/slider03.jpg',desc:'Increases Maximuum Health and Health Regen/Second!\n1 Vitality = 50 Maximum HP / 5HP Per Second'},
  {id:6,title:'Wisdom',img:'/assets/game/slider01.jpg',desc:'Increases Maximum Mana and Mana Regen/Second\n1 Wisdom = 50 Maximum MP / 5MP Per Second'},
]

const DataDisplay = ({title,img,desc,index}:{title:string,img:string,desc:string,index:number}) =>{
  const type = index % 2
  return(
    <div className='flex flex-wrap justify-center md:justify-start even:flex-row odd:flex-row-reverse w-full relative my-16  ' >
      <div className='bg-main w-full md:w-8/12 h-96 md:h-[30rem] rounded-lg'>
        <img
        alt='data'
        src={img}
        className='h-full w-full '
        />
      </div>
      <div className={`${type ? 'right-8' : 'left-8'} -mt-8 w-10/12 md:absolute md:w-2/5 h-[26rem] -top-8`}>
        <div className='border bg-main  border-sub-b h-full w-full borderd rounded-lg px-8 py-12 '>
          <div className='text-4xl font-medium text-center mb-4'>{title}</div>
          <div className='text-xl text-gray-300 tracking-wide'>{desc}</div>
        </div>
      </div>
    </div>    
  )
}



const StatsPage = () =>{
  useEffect(()=>{
    document.title = "Clashing Blades Online | Gameplay | Stats"
  },[])
  return(
    <section className='homerest text-white'>
      <div className='group w-full overflow-hidden h-96 md:h-[40rem] relative'>
        <div className='flex justify-center items-center flex-row h-full w-full z-40 absolute top-0 left-0 right-0 bottom-0 flex-wrap'>
          <div className='flex flex-wrap text-center justify-center'>
            <div className='transition-[opacity,visibility] duration-500 flex justify-center items-center w-full'>
              <div className='px-6 md:py-0 text-white text-center'>
                <div className='text-2xl md:text-4xl uppercase font-serif font-medium tracking-widest'>The Strength of your character</div>
                <div className='flex flex-wrap justify-center w-full mt-2'>
                  <div className='h-0.5 w-4/12 bg-sub-a mx-2 my-2 rounded-full' />
                  <div className='h-0.5 w-8/12 bg-sub-a mx-2 my-2 rounded-full' />
                  <div className='h-0.5 w-full bg-sub-a mx-2 my-2 rounded-full' />

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='absolute bg-sub-a h-full w-full z-20 opacity-40'>

        </div> 
        <div className=' transition-opacity duration-300 h-full w-full -z-10 '>
          <img
            src={`/assets/game/slider03.jpg`}
            alt="cover"
            className="h-full w-full absolute top-0 right-0 z-10 object-cover"
          />
        </div>

      </div>
      <div className='flex flex-wrap w-full flex-col-reverse md:flex-row px-4 md:px-24 py-4 md:py-12 '>
        <div className='w-full md:w-1/2 text-3xl md:text-5xl mt-4 md:mt-0' >
          <span className='font-medium'>
            Stats
          </span>
        </div>
        <div className='w-full md:w-1/2 flex md:items-center' >
          <nav className="bg-grey-light rounded-md w-full">
            <ol className="list-reset flex justify-start md:justify-end">
              <li><Link to="/bte" className="text-gray-400 hover:text-sub-b">Home</Link></li>
              <li><span className="text-sub-b  mx-2">{'>'}</span></li>
              <li><Link to="/bte/gameplay" className="text-gray-400  hover:text-sub-b">Gameplay</Link></li>
              <li><span className="text-sub-b  mx-2">{'>'}</span></li>
              <li><Link to="/bte/gameplay/stats" className="text-white hover:text-sub-b">Stats</Link></li>

            </ol>
          </nav>        
        </div>
      </div>
      <div className='w-full flex justify-center'>
        <div className='max-w-7xl flex flex-wrap w-full px-8 '>
          {
            data.map((dat,index)=>(
              <DataDisplay key={dat.id} index={index} {...dat} />
            ))
          }        
        </div>
      </div>
    </section>
  )
}


export default StatsPage 