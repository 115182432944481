import React , {Fragment, RefObject, useEffect, useRef, useState} from 'react'

import { Link , useLocation } from 'react-router-dom'

import { Popover, Transition } from '@headlessui/react'

import {MenuIcon,XIcon} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'

import Button from './button'
import axios from 'axios'


const gameplay=[
  {id:1,name:'Economy',to:'/bte/gameplay/economy',type:0},
  {id:2,name:'Stats',to:'/bte/gameplay/stats',type:0},
  {id:3,name:'Equipments',to:'/bte/gameplay/equipments',type:0},
  {id:4,name:'Classes',to:'/bte/gameplay/classes',type:0},
  {id:5,name:'Monsters',to:'/bte/gameplay/monsters',type:0},
  {id:6,name:'Nobility',to:'/bte/gameplay/nobility',type:0},
]




const mores=[
  {id:1,name:'Whitepaper',to:'https://docs.clashingblades.com/',type:1},
  {id:3,name:'Lyka',to:'#LYKA',type:1},
  {id:2,name:'Ether',to:'#ETHER',type:0,disabled:true},
  {id:4,name:'Governance',to:'/goverance',type:0,disabled:true},
  {id:5,name:'Ethernia',to:'/Ethernia',type:0,disabled:true},
]


function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

interface linkProps {
  name: string;
  to: string;
  handleHovering:Function;
  navhover:boolean;
}
interface linkDropDownProps {
  name: string;
  links: Array<{id:number,name:string,to:string,type:number,disabled?:boolean}>;
  type:number;
  handleHovering:Function
}

interface linkIconDropDownProps {
  name: string;
  links: Array<{id:number,name:string,link:string,linktype:number,done?:boolean,desc:string}>;
  type:number;
  handleHovering:Function
}



const NavLink = ({name,to,handleHovering,navhover}:linkProps) =>{
  const main = useRef<HTMLAnchorElement|null>(null)
  const location = useLocation();
  useEffect(()=>{
    if(location.pathname===to && location.hash === '' && !navhover){
      handleHovering(main)
    }

  },[location,to,handleHovering,navhover])
  return(
    <Link ref={main} onMouseEnter={()=>handleHovering(main)} className="text-base py-6 uppercase font-medium text-gray-200 hover:text-gray-400" to={to}> 
      {name}
    </Link>
  )
}



const NavIconLinkDropDown = ({name,links,type,handleHovering}:linkIconDropDownProps) =>{
  const main = useRef<HTMLDivElement|null>(null)
  const [hover , setHover] = useState<boolean>(false)
  return(
    <div ref={main} onMouseEnter={(e)=>{handleHovering(main);setHover(true)}} onMouseLeave={()=>setHover(false)} className="group relative">
      <button className='text-gray-200 py-6 uppercase rounded-md inline-flex items-center text-base font-medium hover:text-gray-400'>
        <div>{name}</div>
        <ChevronDownIcon className={classNames(type===1 ? 'text-main' : 'text-main','ml-2 h-5 w-5 group-hover:text-red-100 ')}/>
      </button>     
      <div className={classNames(hover ? "visible opacity-100" : "invisible opacity-0","transition-[opacity,visibility] ease-linear duration-200 absolute z-50 transform px-2 w-screen max-w-sm sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2")}>
        <div className=" rounded-b-lg shadow-lg ring-1 ring-sub-a ring-opacity-5 overflow-hidden">
          <div className="relative grid gap-6 bg-sub-a px-2 py-6 sm:gap-8 sm:py-8 sm:px-6">
          {links ? links.map((link) => (
              !link.done ? 
              <div key={link.id} className="-m-3 p-3 flex items-start rounded-lg text-gray-400">
                <div className="text-base" >
                    <div className="font-bold">{link.name}</div>
                    <div className="text-sm">{link.desc}</div>
                </div>                      
              </div>
              :
              !link.linktype ? 
              <Link to={link.link} className={'hover:text-main -m-3 p-3 flex items-start rounded-lg text-white'} key={link.id}>
                <div className="text-base" >
                    <div className="font-bold">{link.name}</div>
                    <div className="text-sm">{link.desc}</div>
                </div>                      
              </Link>
              :
              <a  href={link.link} className={'hover:text-main -m-3 p-3 flex items-start rounded-lg text-white'} key={link.id}>
                <div className="text-base" >
                    <div className="font-bold">{link.name}</div>
                    <div className="text-sm">{link.desc}</div>
                </div>   
              </a>
            )) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

const NavLinkDropDown = ({name,links,type,handleHovering}:linkDropDownProps) =>{
  const main = useRef<HTMLDivElement|null>(null)
  const [hover , setHover] = useState<boolean>(false)
  return(
    <div ref={main} onMouseEnter={(e)=>{handleHovering(main);setHover(true)}} onMouseLeave={()=>setHover(false)} className="group relative">
      <button className='text-gray-200 py-6 uppercase rounded-md inline-flex items-center text-base font-medium hover:text-gray-400'>
        <div>{name}</div>
        <ChevronDownIcon className={classNames(type===1 ? 'text-main' : 'text-main','ml-2 h-5 w-5 group-hover:text-red-100 ')}/>
      </button>     
      <div className={classNames(hover ? "visible opacity-100" : "invisible opacity-0","transition-[opacity,visibility] ease-linear duration-200 absolute z-50 transform px-2 w-screen max-w-sm sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2")}>
        <div className=" rounded-b-lg shadow-lg ring-1 ring-sub-a ring-opacity-5 overflow-hidden">
          <div className="relative grid gap-6 bg-sub-a px-5 py-6 sm:gap-8 sm:p-8">
          {links.map((link) => (
              link.disabled ? 
              <div key={link.id} className="-m-3 p-3 flex items-start rounded-lg text-gray-400">
                <div className="ml-4 text-base font-medium" >
                  {link.name}
                </div>                      
              </div>
              :
              !link.type ? 
              <Link to={link.to} className={classNames(type===1 ? 'hover:text-main' : 'hover:text-main','-m-3 p-3 flex items-start rounded-lg text-white')} key={link.id}>
                <div className="ml-4 text-base font-medium" >
                  {link.name}
                </div>                      
              </Link>
              :
              <a  href={link.to} className={classNames(type===1 ? 'hover:text-main' : 'hover:text-main','-m-3 p-3 flex items-start rounded-lg text-white')} key={link.id}>
                <div className="ml-4 text-base font-medium" >
                  {link.name}
                </div>   
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}



const Navbar = () =>{
  const [barWidth,setWidth] = useState<number>(0)
  const [barLeft , setLeft] = useState<number>(600)
  const [navhover , setNavhover] = useState<boolean>(false)
  const [siteState , setSiteState] = useState<number>(0)
  const [ projectsData , setProjectsData ] = useState([])
  const location = useLocation();

  const handleHovering = (el : RefObject<HTMLDivElement>)=>{
    let width = barWidth
    let left = barLeft
    width =el.current!.offsetWidth+10
    left = el.current!.offsetLeft-5
    setWidth(width)
    setLeft(left)
  }
  useEffect(()=>{
    const loc =location.pathname.split('/')[1]
    const getData = async () =>{
      const getProjectsData = await axios.get(`/projects/all`)
      setProjectsData(getProjectsData.data)      
    }
    if(loc === 'bte' && siteState !== 1){
      setSiteState(1)
    }
    else if(loc !== 'bte' && siteState !== 0){
      setSiteState(0)

    }
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location])
  return(   
    siteState ?
    <Popover className="relative cb-navbar">
      <div className="mx-auto px-4 sm:px-10 relative" onMouseEnter={()=>setNavhover(true)}>
        <div  className="flex justify-between items-center md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1 ">
            <Link to="/bte" className='py-2'>
              <span className="sr-only">Battle For Ethernia</span>
              <img
                className="h-8 w-20 sm:w-28 sm:h-10"
                src="/assets/logo.png"
                alt=""
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-sub-b rounded-md p-2 inline-flex items-center justify-center text-sub-a hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10" onMouseLeave={()=>{setNavhover(false);setWidth(0)}}>
            <NavLink navhover={navhover} handleHovering={handleHovering} name="Home" to="/bte" />
            <NavLinkDropDown handleHovering={handleHovering} name="Game" links={gameplay} type={1} />
            <NavLinkDropDown handleHovering={handleHovering} name="More" links={mores} type={2} />

          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <Button variant={4}>
			  	    <Link to="/register" >
                  Register
              </Link> 
            </Button>
			
          </div>
        </div>
        <div style={{width:barWidth,left:barLeft}} className={classNames(navhover&&barWidth>1 ? 'block' : '','hidden md:block h-1 top-0 duration-500 absolute bg-sub-b overflow-hidden transition-[width,height,left] rounded-bl-sm rounded-br-sm')} ></div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-sub-a divide-y-2 divide-sub-b">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                <Link to="/">
                  <span className="sr-only">Clashing Blades</span>
                  <img
                    className="h-8 w-auto sm:h-10"
                    src="/assets/Alogo.png"
                    alt=""
                  />
                </Link>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-sub-b rounded-md p-2 inline-flex items-center justify-center text-sub-a hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6 py-6 space-y-6">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  {projectsData.map((link:any) => (
                    !link.done ? 
                    <div key={link.id} className="flex items-start rounded-lg text-gray-400">
                      <div className="ml-3 text-base font-medium" >
                        <div className="font-bold">{link.name}</div>
                        <div className="text-sm">{link.desc}</div>
                      </div>                      
                    </div>
                    :
                    !link.linktype ? 
                    <Link to={link.link} className={'hover:text-main flex items-start rounded-lg text-white'} key={link.id}>
                      <div className="ml-3 text-base font-medium" >
                        <div className="font-bold">{link.name}</div>
                        <div className="text-sm">{link.desc}</div>
                      </div>                      
                    </Link>
                    :
                    <a  href={link.link} className={'hover:text-main flex items-start rounded-lg text-white'} key={link.id}>
                      <div className="ml-3 text-base font-medium" >
                        <div className="font-bold">{link.name}</div>
                        <div className="text-sm">{link.desc}</div>
                      </div>   
                    </a>
                    ))}                
                </div>
            </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link to="/" className="ml-3 text-base font-medium text-gray-200 hover:text-gray-500">
                  Home
                </Link>

                <Link to="/careers" className="ml-3 text-base font-medium text-gray-200 hover:text-gray-500">
                  Careers
                </Link>
                <Link to="/news" className="ml-3 text-base font-medium text-gray-200 hover:text-gray-500">
                  News
                </Link>    
                <Link to="/about" className="ml-3 text-base font-medium text-gray-200 hover:text-gray-500">
                  About
                </Link>                             
              </div>
            </div>            
            <div className="py-6 px-5 space-y-6">
              <div className='text-center'>
                <Button variant={4}>        
                  <Link to="/register" >
                    Register
                  </Link> 
                </Button>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
    :
    <Popover className="relative cb-navbar">
      <div className="mx-auto px-4 sm:px-10 relative" onMouseEnter={()=>setNavhover(true)}>
        <div  className="flex justify-between items-center md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1 ">
            <Link to="/" className='py-2'>
              <span className="sr-only">Battle For Ethernia</span>
              <img
                className="h-8 w-20 sm:w-28 sm:h-10"
                src="/assets/logo.png"
                alt="logo"
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-sub-b rounded-md p-2 inline-flex items-center justify-center text-sub-a hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10" onMouseLeave={()=>{setNavhover(false);setWidth(0)}}>
            <NavLink navhover={navhover} handleHovering={handleHovering} name="Home" to="/" />
            <NavLink navhover={navhover} handleHovering={handleHovering} name="About" to="/about" />
            <NavIconLinkDropDown handleHovering={handleHovering} name="Projects" links={projectsData} type={1} />
            <NavLink navhover={navhover} handleHovering={handleHovering} name="Careers" to="/careers" />
            <NavLink navhover={navhover} handleHovering={handleHovering} name="News" to="/news" />

          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <Button variant={4}>
			  	    <Link to="/register" >
                  Register
              </Link> 
            </Button>
			
          </div>
        </div>
        <div style={{width:barWidth,left:barLeft}} className={classNames(navhover&&barWidth>1 ? 'block' : '','hidden md:block h-1 top-0 duration-500 absolute bg-sub-b overflow-hidden transition-[width,height,left] rounded-bl-sm rounded-br-sm')} ></div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-sub-a divide-y-2 divide-sub-b">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                <Link to="/">
                  <span className="sr-only">Clashing Blades</span>
                  <img
                    className="h-8 w-auto sm:h-10"
                    src="/assets/Alogo.png"
                    alt=""
                  />
                </Link>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-sub-b rounded-md p-2 inline-flex items-center justify-center text-sub-a hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6 py-6 space-y-6">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  {projectsData.map((link:any) => (
                    !link.done ? 
                    <div key={link.id} className="flex items-start rounded-lg text-gray-400">
                      <div className="ml-3 text-base font-medium" >
                        <div className="font-bold">{link.name}</div>
                        <div className="text-sm">{link.desc}</div>
                      </div>                      
                    </div>
                    :
                    !link.linktype ? 
                    <Link to={link.link} className={'hover:text-main flex items-start rounded-lg text-white'} key={link.id}>
                      <div className="ml-3 text-base font-medium" >
                        <div className="font-bold">{link.name}</div>
                        <div className="text-sm">{link.desc}</div>
                      </div>                      
                    </Link>
                    :
                    <a  href={link.link} className={'hover:text-main flex items-start rounded-lg text-white'} key={link.id}>
                      <div className="ml-3 text-base font-medium" >
                        <div className="font-bold">{link.name}</div>
                        <div className="text-sm">{link.desc}</div>
                      </div>   
                    </a>
                    ))}                
                </div>
            </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link to="/" className="ml-3 text-base font-medium text-gray-200 hover:text-gray-500">
                  Home
                </Link>

                <Link to="/careers" className="ml-3 text-base font-medium text-gray-200 hover:text-gray-500">
                  Careers
                </Link>
                <Link to="/news" className="ml-3 text-base font-medium text-gray-200 hover:text-gray-500">
                  News
                </Link>    
                <Link to="/about" className="ml-3 text-base font-medium text-gray-200 hover:text-gray-500">
                  About
                </Link>                             
              </div>
            </div>            
            <div className="py-6 px-5 space-y-6">
              <div className='text-center'>
                <Button variant={4}>        
                  <Link to="/register" >
                    Register
                  </Link> 
                </Button>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>    
  )       
}


export default Navbar